import { computed, inject, Injectable } from '@angular/core';
import { Constants } from '@app/constants';
import { Chapter } from '@model/chapter';
import { MenuItem } from '@model/menu-item';
import { ContentService } from '@service/content.service';
import { Observable, of } from 'rxjs';

@Injectable({ providedIn: 'root' })
export class MenuService {
  private contentService = inject(ContentService);

  menuItems = computed<MenuItem[]>(() => this.buildMenuItems(this.contentService.chapters()));

  private buildMenuItems(chapters: Chapter[]): MenuItem[] {
    if (!chapters || chapters.length === 0) {
      return;
    }

    // NOTE: when adding a new menu item, make sure that the used icon exists in the icons list in AppComponent
    const items: MenuItem[] = [];
    items.push(
      new MenuItem(new Chapter({ name: 'search' } as Chapter)),
      new MenuItem(new Chapter({ name: 'albums' } as Chapter)),
    );

    if (chapters) {
      // Filter the chapters with the menuCode from constants and sort them by menuPosition
      const chapterItems = chapters
        .filter(chapter => chapter.menuPosition >= 0 && chapter.news === false)
        .sort((a, b) => (a.menuPosition - b.menuPosition))
        .map(chapter => new MenuItem(chapter));
      items.push(...chapterItems);
    }

    items.push(
      new MenuItem(new Chapter({ name: 'notifications' } as Chapter)),
      new MenuItem(new Chapter({ name: 'settings' } as Chapter)),
      this.createMenuItem('privacy', Constants.urlPrivacy),
      new MenuItem(new Chapter({ name: 'about' } as Chapter)),
    );

    return items;
  }

  private createMenuItem(item: string, url: string): MenuItem | null {
    if (!url) {
      return null;

    } else {
      const menuItem = new MenuItem(new Chapter({ name: item } as Chapter));
      menuItem.url = url;

      return menuItem;
    }
  }

  initialize(): Observable<void> {
    return of(void 0);
  }
}
